var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.isStaffLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between mb-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function () {
                              this$1.$router.go(-1)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isStaffLoading
                    ? _c(
                        "v-card",
                        { staticClass: "pt-3 pl-3" },
                        [
                          this.$store.getters.getRole.includes("developer")
                            ? _c(
                                "div",
                                { staticClass: "d-flex justify-end mx-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.api.isLoading,
                                      },
                                      on: { click: _vm.redirectDirectLogin },
                                    },
                                    [_vm._v(" Login ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end mx-2" },
                            [
                              this.$store.getters.getRole.includes("operation")
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-3",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.editStaff },
                                    },
                                    [_vm._v(" Edit ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-5",
                                  attrs: { size: "100", color: "primary" },
                                },
                                [_vm._v(" fa-user ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-card-title", [
                            _vm._v(" " + _vm._s(_vm.staff.user_name) + " "),
                          ]),
                          _c(
                            "p",
                            { staticClass: "mt-3 ml-3" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mx-2", attrs: { small: "" } },
                                [_vm._v(" fa-phone ")]
                              ),
                              _vm._v(" " + _vm._s(_vm.staff.mobile) + " "),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "mt-3 ml-3" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mx-2", attrs: { small: "" } },
                                [_vm._v(" mdi-email ")]
                              ),
                              _vm._v(" " + _vm._s(_vm.staff.email) + " "),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mt-3 ml-3" }, [
                            _c("span", { staticClass: "text-h7" }, [
                              _vm._v(" Remark : "),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "my-3 mx-3" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.staff.staff_remark !== null &&
                                                _vm.staff.staff_remark !== ""
                                                ? _vm.staff.staff_remark
                                                : "No remark recorded"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("hr"),
                          this.teamDescription != null
                            ? _c("div", { staticClass: "pb-3" }, [
                                this.teamDescription != null
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mt-3 ml-3 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " Team leader ( " +
                                            _vm._s(_vm.teamDescription.name) +
                                            ") "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                this.teamDescription != null
                                  ? _c(
                                      "p",
                                      { staticClass: "mt-3 ml-3" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mx-2",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v(" fa-users ")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.leader.fname) +
                                            " " +
                                            _vm._s(_vm.leader.lname) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  { staticClass: "ml-3" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(" mdi-email ")]
                                    ),
                                    _vm._v(
                                      " " + _vm._s(_vm.leader.email) + " "
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "mt-3 ml-3 pb-4" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(" fa-users ")]
                                    ),
                                    _vm._v(" No team assigned "),
                                  ],
                                  1
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-card", { staticClass: "my-3" }, [
                    _vm.staffRole != null
                      ? _c(
                          "div",
                          [
                            _c("v-data-table", {
                              attrs: {
                                items: _vm.staffRole,
                                headers: _vm.headersRole,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.role",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.role.role.replaceAll(
                                                "_",
                                                " "
                                              ) +
                                                " ( " +
                                                item.role.platform +
                                                " )"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "top",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-toolbar",
                                          {
                                            staticClass:
                                              "secondary white--text",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v(" Role ")]
                                                ),
                                                _c("v-spacer"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                4256001489
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.isCompanyLoading
                    ? _c("v-skeleton-loader", {
                        ref: "skeleton",
                        attrs: { type: "table" },
                      })
                    : _vm._e(),
                  _c(
                    "v-card",
                    { staticClass: "my-3" },
                    [
                      _vm.companies != null
                        ? _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headersCompanies,
                              items: _vm.companies,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        {
                                          staticClass: "secondary white--text",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {},
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-toolbar-title",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            " Companies in-charge "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-spacer"),
                                                      _vm.rolesAllowedMassAssignation.some(
                                                        function (role) {
                                                          return _vm.$store.getters.getRole.includes(
                                                            role
                                                          )
                                                        }
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-end mr-1",
                                                            },
                                                            [
                                                              _c(
                                                                "JsonCSV",
                                                                {
                                                                  attrs: {
                                                                    data: _vm.downloadCompanies,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mx-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Download "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.tempStaffRole !=
                                                              null
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _vm.rolesAllowedSeeCompanies.some(
                                                                        function (
                                                                          role
                                                                        ) {
                                                                          return _vm.tempStaffRole
                                                                            .map(
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return item.role
                                                                              }
                                                                            )
                                                                            .includes(
                                                                              role
                                                                            )
                                                                        }
                                                                      ) &&
                                                                      _vm.rolesAllowedEditUserDetail.some(
                                                                        function (
                                                                          role
                                                                        ) {
                                                                          return _vm.$store.getters.getRole.includes(
                                                                            role
                                                                          )
                                                                        }
                                                                      )
                                                                        ? _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                  to: {
                                                                                    name: "PagePICCompaniesAssign",
                                                                                    params:
                                                                                      {
                                                                                        id: _vm
                                                                                          .$router
                                                                                          .history
                                                                                          .current
                                                                                          .params
                                                                                          .id,
                                                                                      },
                                                                                  },
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Assign "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2487011682
                            ),
                          })
                        : _c("v-card-title", [
                            _vm._v(" No companies assigned "),
                          ]),
                    ],
                    1
                  ),
                  !_vm.isTaskLoading
                    ? _c("v-card", { staticClass: "my-3" }, [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _vm.task != null
                                ? _c("v-data-table", {
                                    staticClass: "elevation-1",
                                    attrs: {
                                      headers: _vm.headerTask,
                                      items: _vm.task,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  staticClass:
                                                    "white--text secondary",
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "ml-3" },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Task "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("v-spacer"),
                                                              _vm.rolesAllowedMassAssignation.some(
                                                                function (
                                                                  role
                                                                ) {
                                                                  return _vm.$store.getters.getRole.includes(
                                                                    role
                                                                  )
                                                                }
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-end",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "JsonCSV",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              data: _vm.downloadTask,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Download "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.rolesAllowedMassAssignation.some(
                                                                        function (
                                                                          role
                                                                        ) {
                                                                          return _vm.$store.getters.getRole.includes(
                                                                            role
                                                                          )
                                                                        }
                                                                      )
                                                                        ? _c(
                                                                            "v-btn",
                                                                            {
                                                                              staticClass:
                                                                                "mx-3",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  to: {
                                                                                    name: "PageTaskMassAssign",
                                                                                    params:
                                                                                      {
                                                                                        id: _vm
                                                                                          .$router
                                                                                          .history
                                                                                          .current
                                                                                          .params
                                                                                          .id,
                                                                                      },
                                                                                  },
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Assign "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2822160309
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isTeamLoading
                    ? _c(
                        "v-card",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                { staticClass: " white--text secondary" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-3 ",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "ml-3" },
                                        [
                                          _c("v-row", [
                                            _vm._v(
                                              " Team members ( " +
                                                _vm._s(
                                                  _vm.teamDescription.name
                                                ) +
                                                ") "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                staticClass: "elevation--1",
                                attrs: {
                                  items: this.team,
                                  headers: this.headersTeam,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.name",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.fname) +
                                              " " +
                                              _vm._s(item.lname) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  325638196
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("ALoader", { attrs: { api: this.api } }),
                  this.remark != null
                    ? _c(
                        "v-card",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "secondary white--text" },
                            [_vm._v(" Remark ")]
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation--1",
                            attrs: {
                              items: _vm.remark,
                              headers: _vm.headerRemark,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }